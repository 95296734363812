:root {
    --ifm-color-primary: #25c2a0;
    --ifm-color-primary-dark: #21af90;
    --ifm-color-primary-darker: #1fa588;
    --ifm-color-primary-darkest: #1a8870;
    --ifm-color-primary-light: #46cbae;
    --ifm-color-primary-lighter: #66d4bd;
    --ifm-color-primary-lightest: #92e0d0;
}

.startButton {
    width: 100%;
    min-width: 100px;
    color: red;
}

.visualizeCanvasWrapper {
    position: relative;
    width: 100%;
    height: 0;
    padding-top: 100%;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;

    .visualizeCanvas {
        position: absolute;
    }
}

.tabItem {
    margin-top: 0 !important;
}