.col,
.container {
    padding: 0 var(--ifm-spacing-horizontal);
    width: 100%
}

.markdown>h2,
.markdown>h3,
.markdown>h4,
.markdown>h5,
.markdown>h6 {
    margin-bottom: calc(var(--ifm-heading-vertical-rhythm-bottom)*var(--ifm-leading))
}

.markdown li,
body {
    word-wrap: break-word
}

body,
ol ol,
ol ul,
ul ol,
ul ul {
    margin: 0
}

pre,
table {
    overflow: auto
}

blockquote,
pre {
    margin: 0 0 var(--ifm-spacing-vertical)
}

.breadcrumbs__link,
.button {
    transition-timing-function: var(--ifm-transition-timing-default)
}

.button,
code {
    vertical-align: middle
}

.button--outline.button--active,
.button--outline:active,
.button--outline:hover,
:root {
    --ifm-button-color: var(--ifm-font-color-base-inverse)
}

.menu__link:hover,
a {
    transition: color var(--ifm-transition-fast) var(--ifm-transition-timing-default)
}

.navbar--dark,
:root {
    --ifm-navbar-link-hover-color: var(--ifm-color-primary)
}

.menu,
.navbar-sidebar {
    overflow-x: hidden
}

:root,
html[data-theme=dark] {
    --ifm-color-emphasis-500: var(--ifm-color-gray-500)
}

.toggleButton_gllP,
html {
    -webkit-tap-highlight-color: transparent
}

.clean-list,
.containsTaskList_mC6p,
.details_lb9f>summary,
.dropdown__menu,
.menu__list {
    list-style: none
}

:root {
    --ifm-color-scheme: light;
    --ifm-dark-value: 10%;
    --ifm-darker-value: 15%;
    --ifm-darkest-value: 30%;
    --ifm-light-value: 15%;
    --ifm-lighter-value: 30%;
    --ifm-lightest-value: 50%;
    --ifm-contrast-background-value: 90%;
    --ifm-contrast-foreground-value: 70%;
    --ifm-contrast-background-dark-value: 70%;
    --ifm-contrast-foreground-dark-value: 90%;
    --ifm-color-primary: #3578e5;
    --ifm-color-secondary: #ebedf0;
    --ifm-color-success: #00a400;
    --ifm-color-info: #54c7ec;
    --ifm-color-warning: #ffba00;
    --ifm-color-danger: #fa383e;
    --ifm-color-primary-dark: #306cce;
    --ifm-color-primary-darker: #2d66c3;
    --ifm-color-primary-darkest: #2554a0;
    --ifm-color-primary-light: #538ce9;
    --ifm-color-primary-lighter: #72a1ed;
    --ifm-color-primary-lightest: #9abcf2;
    --ifm-color-primary-contrast-background: #ebf2fc;
    --ifm-color-primary-contrast-foreground: #102445;
    --ifm-color-secondary-dark: #d4d5d8;
    --ifm-color-secondary-darker: #c8c9cc;
    --ifm-color-secondary-darkest: #a4a6a8;
    --ifm-color-secondary-light: #eef0f2;
    --ifm-color-secondary-lighter: #f1f2f5;
    --ifm-color-secondary-lightest: #f5f6f8;
    --ifm-color-secondary-contrast-background: #fdfdfe;
    --ifm-color-secondary-contrast-foreground: #474748;
    --ifm-color-success-dark: #009400;
    --ifm-color-success-darker: #008b00;
    --ifm-color-success-darkest: #007300;
    --ifm-color-success-light: #26b226;
    --ifm-color-success-lighter: #4dbf4d;
    --ifm-color-success-lightest: #80d280;
    --ifm-color-success-contrast-background: #e6f6e6;
    --ifm-color-success-contrast-foreground: #003100;
    --ifm-color-info-dark: #4cb3d4;
    --ifm-color-info-darker: #47a9c9;
    --ifm-color-info-darkest: #3b8ba5;
    --ifm-color-info-light: #6ecfef;
    --ifm-color-info-lighter: #87d8f2;
    --ifm-color-info-lightest: #aae3f6;
    --ifm-color-info-contrast-background: #eef9fd;
    --ifm-color-info-contrast-foreground: #193c47;
    --ifm-color-warning-dark: #e6a700;
    --ifm-color-warning-darker: #d99e00;
    --ifm-color-warning-darkest: #b38200;
    --ifm-color-warning-light: #ffc426;
    --ifm-color-warning-lighter: #ffcf4d;
    --ifm-color-warning-lightest: #ffdd80;
    --ifm-color-warning-contrast-background: #fff8e6;
    --ifm-color-warning-contrast-foreground: #4d3800;
    --ifm-color-danger-dark: #e13238;
    --ifm-color-danger-darker: #d53035;
    --ifm-color-danger-darkest: #af272b;
    --ifm-color-danger-light: #fb565b;
    --ifm-color-danger-lighter: #fb7478;
    --ifm-color-danger-lightest: #fd9c9f;
    --ifm-color-danger-contrast-background: #ffebec;
    --ifm-color-danger-contrast-foreground: #4b1113;
    --ifm-color-white: #fff;
    --ifm-color-black: #000;
    --ifm-color-gray-0: var(--ifm-color-white);
    --ifm-color-gray-100: #f5f6f7;
    --ifm-color-gray-200: #ebedf0;
    --ifm-color-gray-300: #dadde1;
    --ifm-color-gray-400: #ccd0d5;
    --ifm-color-gray-500: #bec3c9;
    --ifm-color-gray-600: #8d949e;
    --ifm-color-gray-700: #606770;
    --ifm-color-gray-800: #444950;
    --ifm-color-gray-900: #1c1e21;
    --ifm-color-gray-1000: var(--ifm-color-black);
    --ifm-color-emphasis-0: var(--ifm-color-gray-0);
    --ifm-color-emphasis-100: var(--ifm-color-gray-100);
    --ifm-color-emphasis-200: var(--ifm-color-gray-200);
    --ifm-color-emphasis-300: var(--ifm-color-gray-300);
    --ifm-color-emphasis-400: var(--ifm-color-gray-400);
    --ifm-color-emphasis-600: var(--ifm-color-gray-600);
    --ifm-color-emphasis-700: var(--ifm-color-gray-700);
    --ifm-color-emphasis-800: var(--ifm-color-gray-800);
    --ifm-color-emphasis-900: var(--ifm-color-gray-900);
    --ifm-color-emphasis-1000: var(--ifm-color-gray-1000);
    --ifm-color-content: var(--ifm-color-emphasis-900);
    --ifm-color-content-inverse: var(--ifm-color-emphasis-0);
    --ifm-color-content-secondary: #525860;
    --ifm-background-color: transparent;
    --ifm-background-surface-color: var(--ifm-color-content-inverse);
    --ifm-global-border-width: 1px;
    --ifm-global-radius: 0.4rem;
    --ifm-hover-overlay: rgba(0, 0, 0, .05);
    --ifm-font-color-base: var(--ifm-color-content);
    --ifm-font-color-base-inverse: var(--ifm-color-content-inverse);
    --ifm-font-color-secondary: var(--ifm-color-content-secondary);
    --ifm-font-family-base: system-ui, -apple-system, Segoe UI, Roboto, Ubuntu, Cantarell, Noto Sans, sans-serif, BlinkMacSystemFont, "Segoe UI", Helvetica, Arial, sans-serif, "Apple Color Emoji", "Segoe UI Emoji", "Segoe UI Symbol";
    --ifm-font-family-monospace: SFMono-Regular, Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace;
    --ifm-font-size-base: 100%;
    --ifm-font-weight-light: 300;
    --ifm-font-weight-normal: 400;
    --ifm-font-weight-semibold: 500;
    --ifm-font-weight-bold: 700;
    --ifm-font-weight-base: var(--ifm-font-weight-normal);
    --ifm-line-height-base: 1.65;
    --ifm-global-spacing: 1rem;
    --ifm-spacing-vertical: var(--ifm-global-spacing);
    --ifm-spacing-horizontal: var(--ifm-global-spacing);
    --ifm-transition-fast: 200ms;
    --ifm-transition-slow: 400ms;
    --ifm-transition-timing-default: cubic-bezier(0.08, 0.52, 0.52, 1);
    --ifm-global-shadow-lw: 0 1px 2px 0 rgba(0, 0, 0, .1);
    --ifm-global-shadow-md: 0 5px 40px rgba(0, 0, 0, .2);
    --ifm-global-shadow-tl: 0 12px 28px 0 rgba(0, 0, 0, .2), 0 2px 4px 0 rgba(0, 0, 0, .1);
    --ifm-z-index-dropdown: 100;
    --ifm-z-index-fixed: 200;
    --ifm-z-index-overlay: 400;
    --ifm-container-width: 1140px;
    --ifm-container-width-xl: 1320px;
    --ifm-code-background: #f6f7f8;
    --ifm-code-border-radius: var(--ifm-global-radius);
    --ifm-code-font-size: 90%;
    --ifm-code-padding-horizontal: 0.1rem;
    --ifm-code-padding-vertical: 0.1rem;
    --ifm-pre-background: var(--ifm-code-background);
    --ifm-pre-border-radius: var(--ifm-code-border-radius);
    --ifm-pre-color: inherit;
    --ifm-pre-line-height: 1.45;
    --ifm-pre-padding: 1rem;
    --ifm-heading-color: inherit;
    --ifm-heading-margin-top: 0;
    --ifm-heading-margin-bottom: var(--ifm-spacing-vertical);
    --ifm-heading-font-family: var(--ifm-font-family-base);
    --ifm-heading-font-weight: var(--ifm-font-weight-bold);
    --ifm-heading-line-height: 1.25;
    --ifm-h1-font-size: 2rem;
    --ifm-h2-font-size: 1.5rem;
    --ifm-h3-font-size: 1.25rem;
    --ifm-h4-font-size: 1rem;
    --ifm-h5-font-size: 0.875rem;
    --ifm-h6-font-size: 0.85rem;
    --ifm-image-alignment-padding: 1.25rem;
    --ifm-leading-desktop: 1.25;
    --ifm-leading: calc(var(--ifm-leading-desktop)*1rem);
    --ifm-list-left-padding: 2rem;
    --ifm-list-margin: 1rem;
    --ifm-list-item-margin: 0.25rem;
    --ifm-list-paragraph-margin: 1rem;
    --ifm-table-cell-padding: 0.75rem;
    --ifm-table-background: transparent;
    --ifm-table-stripe-background: rgba(0, 0, 0, .03);
    --ifm-table-border-width: 1px;
    --ifm-table-border-color: var(--ifm-color-emphasis-300);
    --ifm-table-head-background: inherit;
    --ifm-table-head-color: inherit;
    --ifm-table-head-font-weight: var(--ifm-font-weight-bold);
    --ifm-table-cell-color: inherit;
    --ifm-link-color: var(--ifm-color-primary);
    --ifm-link-decoration: none;
    --ifm-link-hover-color: var(--ifm-link-color);
    --ifm-link-hover-decoration: underline;
    --ifm-paragraph-margin-bottom: var(--ifm-leading);
    --ifm-blockquote-font-size: var(--ifm-font-size-base);
    --ifm-blockquote-border-left-width: 2px;
    --ifm-blockquote-padding-horizontal: var(--ifm-spacing-horizontal);
    --ifm-blockquote-padding-vertical: 0;
    --ifm-blockquote-shadow: none;
    --ifm-blockquote-color: var(--ifm-color-emphasis-800);
    --ifm-blockquote-border-color: var(--ifm-color-emphasis-300);
    --ifm-hr-background-color: var(--ifm-color-emphasis-500);
    --ifm-hr-height: 1px;
    --ifm-hr-margin-vertical: 1.5rem;
    --ifm-scrollbar-size: 7px;
    --ifm-scrollbar-track-background-color: #f1f1f1;
    --ifm-scrollbar-thumb-background-color: silver;
    --ifm-scrollbar-thumb-hover-background-color: #a7a7a7;
    --ifm-alert-background-color: inherit;
    --ifm-alert-border-color: inherit;
    --ifm-alert-border-radius: var(--ifm-global-radius);
    --ifm-alert-border-width: 0px;
    --ifm-alert-border-left-width: 5px;
    --ifm-alert-color: var(--ifm-font-color-base);
    --ifm-alert-padding-horizontal: var(--ifm-spacing-horizontal);
    --ifm-alert-padding-vertical: var(--ifm-spacing-vertical);
    --ifm-alert-shadow: var(--ifm-global-shadow-lw);
    --ifm-avatar-intro-margin: 1rem;
    --ifm-avatar-intro-alignment: inherit;
    --ifm-avatar-photo-size: 3rem;
    --ifm-badge-background-color: inherit;
    --ifm-badge-border-color: inherit;
    --ifm-badge-border-radius: var(--ifm-global-radius);
    --ifm-badge-border-width: var(--ifm-global-border-width);
    --ifm-badge-color: var(--ifm-color-white);
    --ifm-badge-padding-horizontal: calc(var(--ifm-spacing-horizontal)*0.5);
    --ifm-badge-padding-vertical: calc(var(--ifm-spacing-vertical)*0.25);
    --ifm-breadcrumb-border-radius: 1.5rem;
    --ifm-breadcrumb-spacing: 0.5rem;
    --ifm-breadcrumb-color-active: var(--ifm-color-primary);
    --ifm-breadcrumb-item-background-active: var(--ifm-hover-overlay);
    --ifm-breadcrumb-padding-horizontal: 0.8rem;
    --ifm-breadcrumb-padding-vertical: 0.4rem;
    --ifm-breadcrumb-size-multiplier: 1;
    --ifm-breadcrumb-separator: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 256 256"><g><g><polygon points="79.093,0 48.907,30.187 146.72,128 48.907,225.813 79.093,256 207.093,128"/></g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g><g></g></svg>');
    --ifm-breadcrumb-separator-filter: none;
    --ifm-breadcrumb-separator-size: 0.5rem;
    --ifm-breadcrumb-separator-size-multiplier: 1.25;
    --ifm-button-background-color: inherit;
    --ifm-button-border-color: var(--ifm-button-background-color);
    --ifm-button-border-width: var(--ifm-global-border-width);
    --ifm-button-font-weight: var(--ifm-font-weight-bold);
    --ifm-button-padding-horizontal: 1.5rem;
    --ifm-button-padding-vertical: 0.375rem;
    --ifm-button-size-multiplier: 1;
    --ifm-button-transition-duration: var(--ifm-transition-fast);
    --ifm-button-border-radius: calc(var(--ifm-global-radius)*var(--ifm-button-size-multiplier));
    --ifm-button-group-spacing: 2px;
    --ifm-card-background-color: var(--ifm-background-surface-color);
    --ifm-card-border-radius: calc(var(--ifm-global-radius)*2);
    --ifm-card-horizontal-spacing: var(--ifm-global-spacing);
    --ifm-card-vertical-spacing: var(--ifm-global-spacing);
    --ifm-toc-border-color: var(--ifm-color-emphasis-300);
    --ifm-toc-link-color: var(--ifm-color-content-secondary);
    --ifm-toc-padding-vertical: 0.5rem;
    --ifm-toc-padding-horizontal: 0.5rem;
    --ifm-dropdown-background-color: var(--ifm-background-surface-color);
    --ifm-dropdown-font-weight: var(--ifm-font-weight-semibold);
    --ifm-dropdown-link-color: var(--ifm-font-color-base);
    --ifm-dropdown-hover-background-color: var(--ifm-hover-overlay);
    --ifm-footer-background-color: var(--ifm-color-emphasis-100);
    --ifm-footer-color: inherit;
    --ifm-footer-link-color: var(--ifm-color-emphasis-700);
    --ifm-footer-link-hover-color: var(--ifm-color-primary);
    --ifm-footer-link-horizontal-spacing: 0.5rem;
    --ifm-footer-padding-horizontal: calc(var(--ifm-spacing-horizontal)*2);
    --ifm-footer-padding-vertical: calc(var(--ifm-spacing-vertical)*2);
    --ifm-footer-title-color: inherit;
    --ifm-footer-logo-max-width: min(30rem, 90vw);
    --ifm-hero-background-color: var(--ifm-background-surface-color);
    --ifm-hero-text-color: var(--ifm-color-emphasis-800);
    --ifm-menu-color: var(--ifm-color-emphasis-700);
    --ifm-menu-color-active: var(--ifm-color-primary);
    --ifm-menu-color-background-active: var(--ifm-hover-overlay);
    --ifm-menu-color-background-hover: var(--ifm-hover-overlay);
    --ifm-menu-link-padding-horizontal: 0.75rem;
    --ifm-menu-link-padding-vertical: 0.375rem;
    --ifm-menu-link-sublist-icon: url('data:image/svg+xml;utf8,<svg xmlns="http://www.w3.org/2000/svg" width="16px" height="16px" viewBox="0 0 24 24"><path fill="rgba(0,0,0,0.5)" d="M7.41 15.41L12 10.83l4.59 4.58L18 14l-6-6-6 6z"></path></svg>');
    --ifm-menu-link-sublist-icon-filter: none;
    --ifm-navbar-background-color: var(--ifm-background-surface-color);
    --ifm-navbar-height: 3.75rem;
    --ifm-navbar-item-padding-horizontal: 0.75rem;
    --ifm-navbar-item-padding-vertical: 0.25rem;
    --ifm-navbar-link-color: var(--ifm-font-color-base);
    --ifm-navbar-link-active-color: var(--ifm-link-color);
    --ifm-navbar-padding-horizontal: var(--ifm-spacing-horizontal);
    --ifm-navbar-padding-vertical: calc(var(--ifm-spacing-vertical)*0.5);
    --ifm-navbar-shadow: var(--ifm-global-shadow-lw);
    --ifm-navbar-search-input-background-color: var(--ifm-color-emphasis-200);
    --ifm-navbar-search-input-color: var(--ifm-color-emphasis-800);
    --ifm-navbar-search-input-placeholder-color: var(--ifm-color-emphasis-500);
    --ifm-navbar-search-input-icon: url('data:image/svg+xml;utf8,<svg fill="currentColor" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 16 16" height="16px" width="16px"><path d="M6.02945,10.20327a4.17382,4.17382,0,1,1,4.17382-4.17382A4.15609,4.15609,0,0,1,6.02945,10.20327Zm9.69195,4.2199L10.8989,9.59979A5.88021,5.88021,0,0,0,12.058,6.02856,6.00467,6.00467,0,1,0,9.59979,10.8989l4.82338,4.82338a.89729.89729,0,0,0,1.29912,0,.89749.89749,0,0,0-.00087-1.29909Z" /></svg>');
    --ifm-navbar-sidebar-width: 83vw;
    --ifm-pagination-border-radius: var(--ifm-global-radius);
    --ifm-pagination-color-active: var(--ifm-color-primary);
    --ifm-pagination-font-size: 1rem;
    --ifm-pagination-item-active-background: var(--ifm-hover-overlay);
    --ifm-pagination-page-spacing: 0.2em;
    --ifm-pagination-padding-horizontal: calc(var(--ifm-spacing-horizontal)*1);
    --ifm-pagination-padding-vertical: calc(var(--ifm-spacing-vertical)*0.25);
    --ifm-pagination-nav-border-radius: var(--ifm-global-radius);
    --ifm-pagination-nav-color-hover: var(--ifm-color-primary);
    --ifm-pills-color-active: var(--ifm-color-primary);
    --ifm-pills-color-background-active: var(--ifm-hover-overlay);
    --ifm-pills-spacing: 0.125rem;
    --ifm-tabs-color: var(--ifm-font-color-secondary);
    --ifm-tabs-color-active: var(--ifm-color-primary);
    --ifm-tabs-color-active-border: var(--ifm-tabs-color-active);
    --ifm-tabs-padding-horizontal: 1rem;
    --ifm-tabs-padding-vertical: 1rem;
    --docusaurus-progress-bar-color: var(--ifm-color-primary);
    --ifm-color-primary: #25c2a0;
    --ifm-color-primary-dark: #21af90;
    --ifm-color-primary-darker: #1fa588;
    --ifm-color-primary-darkest: #1a8870;
    --ifm-color-primary-light: #46cbae;
    --ifm-color-primary-lighter: #66d4bd;
    --ifm-color-primary-lightest: #92e0d0;
    --ifm-code-font-size: 95%;
    --docusaurus-announcement-bar-height: auto;
    --docusaurus-collapse-button-bg: transparent;
    --docusaurus-collapse-button-bg-hover: rgba(0, 0, 0, .1);
    --doc-sidebar-width: 300px;
    --doc-sidebar-hidden-width: 30px;
    --docusaurus-tag-list-border: var(--ifm-color-emphasis-300)
}

.badge--danger,
.badge--info,
.badge--primary,
.badge--secondary,
.badge--success,
.badge--warning {
    --ifm-badge-border-color: var(--ifm-badge-background-color)
}

.button--link,
.button--outline {
    --ifm-button-background-color: transparent
}

* {
    box-sizing: border-box
}

html {
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizelegibility;
    -webkit-text-size-adjust: 100%;
    text-size-adjust: 100%;
    background-color: var(--ifm-background-color);
    color: var(--ifm-font-color-base);
    color-scheme: var(--ifm-color-scheme);
    font: var(--ifm-font-size-base)/var(--ifm-line-height-base) var(--ifm-font-family-base)
}

iframe {
    border: 0;
    color-scheme: auto
}

.container {
    margin: 0 auto;
    max-width: var(--ifm-container-width)
}

.container--fluid {
    max-width: inherit
}

.row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 calc(var(--ifm-spacing-horizontal)*-1)
}

.margin-bottom--none,
.margin-vert--none,
.markdown>:last-child {
    margin-bottom: 0 !important
}

.margin-top--none,
.margin-vert--none,
.tabItem_LNqP,
.tabItem_gCJA {
    margin-top: 0 !important
}

.row--no-gutters {
    margin-left: 0;
    margin-right: 0
}

.margin-horiz--none,
.margin-right--none {
    margin-right: 0 !important
}

.row--no-gutters>.col {
    padding-left: 0;
    padding-right: 0
}

.row--align-top {
    align-items: flex-start
}

.row--align-bottom {
    align-items: flex-end
}

.menuExternalLink_NmtK,
.row--align-center {
    align-items: center
}

.row--align-stretch {
    align-items: stretch
}

.row--align-baseline {
    align-items: baseline
}

.col {
    --ifm-col-width: 100%;
    flex: 1 0;
    margin-left: 0;
    max-width: var(--ifm-col-width)
}

.padding-bottom--none,
.padding-vert--none {
    padding-bottom: 0 !important
}

.padding-top--none,
.padding-vert--none {
    padding-top: 0 !important
}

.padding-horiz--none,
.padding-left--none {
    padding-left: 0 !important
}

.padding-horiz--none,
.padding-right--none {
    padding-right: 0 !important
}

.col[class*=col--] {
    flex: 0 0 var(--ifm-col-width)
}

.col--1 {
    --ifm-col-width: 8.33333%
}

.col--offset-1 {
    margin-left: 8.33333%
}

.col--2 {
    --ifm-col-width: 16.66667%
}

.col--offset-2 {
    margin-left: 16.66667%
}

.col--3 {
    --ifm-col-width: 25%
}

.col--offset-3 {
    margin-left: 25%
}

.col--4 {
    --ifm-col-width: 33.33333%
}

.col--offset-4 {
    margin-left: 33.33333%
}

.col--5 {
    --ifm-col-width: 41.66667%
}

.col--offset-5 {
    margin-left: 41.66667%
}

.col--6 {
    --ifm-col-width: 50%
}

.col--offset-6 {
    margin-left: 50%
}

.col--7 {
    --ifm-col-width: 58.33333%
}

.col--offset-7 {
    margin-left: 58.33333%
}

.col--8 {
    --ifm-col-width: 66.66667%
}

.col--offset-8 {
    margin-left: 66.66667%
}

.col--9 {
    --ifm-col-width: 75%
}

.col--offset-9 {
    margin-left: 75%
}

.col--10 {
    --ifm-col-width: 83.33333%
}

.col--offset-10 {
    margin-left: 83.33333%
}

.col--11 {
    --ifm-col-width: 91.66667%
}

.col--offset-11 {
    margin-left: 91.66667%
}

.col--12 {
    --ifm-col-width: 100%
}

.col--offset-12 {
    margin-left: 100%
}

.margin-horiz--none,
.margin-left--none {
    margin-left: 0 !important
}

.margin--none {
    margin: 0 !important
}

.margin-bottom--xs,
.margin-vert--xs {
    margin-bottom: .25rem !important
}

.margin-top--xs,
.margin-vert--xs {
    margin-top: .25rem !important
}

.margin-horiz--xs,
.margin-left--xs {
    margin-left: .25rem !important
}

.margin-horiz--xs,
.margin-right--xs {
    margin-right: .25rem !important
}

.margin--xs {
    margin: .25rem !important
}

.margin-bottom--sm,
.margin-vert--sm {
    margin-bottom: .5rem !important
}

.margin-top--sm,
.margin-vert--sm {
    margin-top: .5rem !important
}

.margin-horiz--sm,
.margin-left--sm {
    margin-left: .5rem !important
}

.margin-horiz--sm,
.margin-right--sm {
    margin-right: .5rem !important
}

.margin--sm {
    margin: .5rem !important
}

.margin-bottom--md,
.margin-vert--md {
    margin-bottom: 1rem !important
}

.margin-top--md,
.margin-vert--md {
    margin-top: 1rem !important
}

.margin-horiz--md,
.margin-left--md {
    margin-left: 1rem !important
}

.margin-horiz--md,
.margin-right--md {
    margin-right: 1rem !important
}

.margin--md {
    margin: 1rem !important
}

.margin-bottom--lg,
.margin-vert--lg {
    margin-bottom: 2rem !important
}

.margin-top--lg,
.margin-vert--lg {
    margin-top: 2rem !important
}

.margin-horiz--lg,
.margin-left--lg {
    margin-left: 2rem !important
}

.margin-horiz--lg,
.margin-right--lg {
    margin-right: 2rem !important
}

.margin--lg {
    margin: 2rem !important
}

.margin-bottom--xl,
.margin-vert--xl {
    margin-bottom: 5rem !important
}

.margin-top--xl,
.margin-vert--xl {
    margin-top: 5rem !important
}

.margin-horiz--xl,
.margin-left--xl {
    margin-left: 5rem !important
}

.margin-horiz--xl,
.margin-right--xl {
    margin-right: 5rem !important
}

.margin--xl {
    margin: 5rem !important
}

.padding--none {
    padding: 0 !important
}

.padding-bottom--xs,
.padding-vert--xs {
    padding-bottom: .25rem !important
}

.padding-top--xs,
.padding-vert--xs {
    padding-top: .25rem !important
}

.padding-horiz--xs,
.padding-left--xs {
    padding-left: .25rem !important
}

.padding-horiz--xs,
.padding-right--xs {
    padding-right: .25rem !important
}

.padding--xs {
    padding: .25rem !important
}

.padding-bottom--sm,
.padding-vert--sm {
    padding-bottom: .5rem !important
}

.padding-top--sm,
.padding-vert--sm {
    padding-top: .5rem !important
}

.padding-horiz--sm,
.padding-left--sm {
    padding-left: .5rem !important
}

.padding-horiz--sm,
.padding-right--sm {
    padding-right: .5rem !important
}

.padding--sm {
    padding: .5rem !important
}

.padding-bottom--md,
.padding-vert--md {
    padding-bottom: 1rem !important
}

.padding-top--md,
.padding-vert--md {
    padding-top: 1rem !important
}

.padding-horiz--md,
.padding-left--md {
    padding-left: 1rem !important
}

.padding-horiz--md,
.padding-right--md {
    padding-right: 1rem !important
}

.padding--md {
    padding: 1rem !important
}

.padding-bottom--lg,
.padding-vert--lg {
    padding-bottom: 2rem !important
}

.padding-top--lg,
.padding-vert--lg {
    padding-top: 2rem !important
}

.padding-horiz--lg,
.padding-left--lg {
    padding-left: 2rem !important
}

.padding-horiz--lg,
.padding-right--lg {
    padding-right: 2rem !important
}

.padding--lg {
    padding: 2rem !important
}

.padding-bottom--xl,
.padding-vert--xl {
    padding-bottom: 5rem !important
}

.padding-top--xl,
.padding-vert--xl {
    padding-top: 5rem !important
}

.padding-horiz--xl,
.padding-left--xl {
    padding-left: 5rem !important
}

.padding-horiz--xl,
.padding-right--xl {
    padding-right: 5rem !important
}

.padding--xl {
    padding: 5rem !important
}

code {
    background-color: var(--ifm-code-background);
    border: .1rem solid rgba(0, 0, 0, .1);
    border-radius: var(--ifm-code-border-radius);
    font-family: var(--ifm-font-family-monospace);
    font-size: var(--ifm-code-font-size);
    padding: var(--ifm-code-padding-vertical) var(--ifm-code-padding-horizontal)
}

a code {
    color: inherit
}

pre {
    background-color: var(--ifm-pre-background);
    border-radius: var(--ifm-pre-border-radius);
    color: var(--ifm-pre-color);
    font: var(--ifm-code-font-size)/var(--ifm-pre-line-height) var(--ifm-font-family-monospace);
    padding: var(--ifm-pre-padding)
}

pre code {
    background-color: transparent;
    border: none;
    font-size: 100%;
    line-height: inherit;
    padding: 0
}

kbd {
    background-color: var(--ifm-color-emphasis-0);
    border: 1px solid var(--ifm-color-emphasis-400);
    border-radius: .2rem;
    box-shadow: inset 0 -1px 0 var(--ifm-color-emphasis-400);
    color: var(--ifm-color-emphasis-800);
    font: 80% var(--ifm-font-family-monospace);
    padding: .15rem .3rem
}

h1,
h2,
h3,
h4,
h5,
h6 {
    color: var(--ifm-heading-color);
    font-family: var(--ifm-heading-font-family);
    font-weight: var(--ifm-heading-font-weight);
    line-height: var(--ifm-heading-line-height);
    margin: var(--ifm-heading-margin-top) 0 var(--ifm-heading-margin-bottom) 0
}

h1 {
    font-size: var(--ifm-h1-font-size)
}

h2 {
    font-size: var(--ifm-h2-font-size)
}

h3 {
    font-size: var(--ifm-h3-font-size)
}

h4 {
    font-size: var(--ifm-h4-font-size)
}

h5 {
    font-size: var(--ifm-h5-font-size)
}

h6 {
    font-size: var(--ifm-h6-font-size)
}

img {
    max-width: 100%
}

img[align=right] {
    padding-left: var(--image-alignment-padding)
}

img[align=left] {
    padding-right: var(--image-alignment-padding)
}

.markdown {
    --ifm-h1-vertical-rhythm-top: 3;
    --ifm-h2-vertical-rhythm-top: 2;
    --ifm-h3-vertical-rhythm-top: 1.5;
    --ifm-heading-vertical-rhythm-top: 1.25;
    --ifm-h1-vertical-rhythm-bottom: 1.25;
    --ifm-heading-vertical-rhythm-bottom: 1
}

.markdown:after,
.markdown:before {
    content: "";
    display: table
}

.markdown:after {
    clear: both
}

.markdown h1:first-child {
    --ifm-h1-font-size: 3rem;
    margin-bottom: calc(var(--ifm-h1-vertical-rhythm-bottom)*var(--ifm-leading))
}

.markdown>h2 {
    --ifm-h2-font-size: 2rem;
    margin-top: calc(var(--ifm-h2-vertical-rhythm-top)*var(--ifm-leading))
}

.markdown>h3 {
    --ifm-h3-font-size: 1.5rem;
    margin-top: calc(var(--ifm-h3-vertical-rhythm-top)*var(--ifm-leading))
}

.markdown>h4,
.markdown>h5,
.markdown>h6 {
    margin-top: calc(var(--ifm-heading-vertical-rhythm-top)*var(--ifm-leading))
}

.markdown>p,
.markdown>pre,
.markdown>ul,
.tabList__CuJ {
    margin-bottom: var(--ifm-leading)
}

.markdown li>p {
    margin-top: var(--ifm-list-paragraph-margin)
}

.markdown li+li {
    margin-top: var(--ifm-list-item-margin)
}

ol,
ul {
    margin: 0 0 var(--ifm-list-margin);
    padding-left: var(--ifm-list-left-padding)
}

ol ol,
ul ol {
    list-style-type: lower-roman
}

ol ol ol,
ol ul ol,
ul ol ol,
ul ul ol {
    list-style-type: lower-alpha
}

table {
    border-collapse: collapse;
    display: block;
    margin-bottom: var(--ifm-spacing-vertical)
}

table thead tr {
    border-bottom: 2px solid var(--ifm-table-border-color)
}

table thead,
table tr:nth-child(2n) {
    background-color: var(--ifm-table-stripe-background)
}

table tr {
    background-color: var(--ifm-table-background);
    border-top: var(--ifm-table-border-width) solid var(--ifm-table-border-color)
}

table td,
table th {
    border: var(--ifm-table-border-width) solid var(--ifm-table-border-color);
    padding: var(--ifm-table-cell-padding)
}

table th {
    background-color: var(--ifm-table-head-background);
    color: var(--ifm-table-head-color);
    font-weight: var(--ifm-table-head-font-weight)
}

table td {
    color: var(--ifm-table-cell-color)
}

strong {
    font-weight: var(--ifm-font-weight-bold)
}

a {
    color: var(--ifm-link-color);
    text-decoration: var(--ifm-link-decoration)
}

a:hover {
    color: var(--ifm-link-hover-color);
    text-decoration: var(--ifm-link-hover-decoration)
}

.button:hover,
.text--no-decoration,
.text--no-decoration:hover,
a:not([href]) {
    text-decoration: none
}

p {
    margin: 0 0 var(--ifm-paragraph-margin-bottom)
}

blockquote {
    border-left: var(--ifm-blockquote-border-left-width) solid var(--ifm-blockquote-border-color);
    box-shadow: var(--ifm-blockquote-shadow);
    color: var(--ifm-blockquote-color);
    font-size: var(--ifm-blockquote-font-size);
    padding: var(--ifm-blockquote-padding-vertical) var(--ifm-blockquote-padding-horizontal)
}

blockquote>:first-child {
    margin-top: 0
}

blockquote>:last-child {
    margin-bottom: 0
}

hr {
    background-color: var(--ifm-hr-background-color);
    border: 0;
    height: var(--ifm-hr-height);
    margin: var(--ifm-hr-margin-vertical) 0
}

.shadow--lw {
    box-shadow: var(--ifm-global-shadow-lw) !important
}

.shadow--md {
    box-shadow: var(--ifm-global-shadow-md) !important
}

.shadow--tl {
    box-shadow: var(--ifm-global-shadow-tl) !important
}

.text--primary,
.wordWrapButtonEnabled_EoeP .wordWrapButtonIcon_Bwma {
    color: var(--ifm-color-primary)
}

.text--secondary {
    color: var(--ifm-color-secondary)
}

.text--success {
    color: var(--ifm-color-success)
}

.text--info {
    color: var(--ifm-color-info)
}

.text--warning {
    color: var(--ifm-color-warning)
}

.text--danger {
    color: var(--ifm-color-danger)
}

.text--center {
    text-align: center
}

.text--left {
    text-align: left
}

.text--justify {
    text-align: justify
}

.text--right {
    text-align: right
}

.text--capitalize {
    text-transform: capitalize
}

.text--lowercase {
    text-transform: lowercase
}

.admonitionHeading_tbUL,
.alert__heading,
.text--uppercase {
    text-transform: uppercase
}

.text--light {
    font-weight: var(--ifm-font-weight-light)
}

.text--normal {
    font-weight: var(--ifm-font-weight-normal)
}

.text--semibold {
    font-weight: var(--ifm-font-weight-semibold)
}

.text--bold {
    font-weight: var(--ifm-font-weight-bold)
}

.text--italic {
    font-style: italic
}

.text--truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

.text--break {
    word-wrap: break-word !important;
    word-break: break-word !important
}

.clean-btn {
    background: none;
    border: none;
    color: inherit;
    cursor: pointer;
    font-family: inherit;
    padding: 0
}

.alert,
.alert .close {
    color: var(--ifm-alert-foreground-color)
}

.clean-list {
    padding-left: 0
}

.alert--primary {
    --ifm-alert-background-color: var(--ifm-color-primary-contrast-background);
    --ifm-alert-background-color-highlight: rgba(53, 120, 229, .15);
    --ifm-alert-foreground-color: var(--ifm-color-primary-contrast-foreground);
    --ifm-alert-border-color: var(--ifm-color-primary-dark)
}

.alert--secondary {
    --ifm-alert-background-color: var(--ifm-color-secondary-contrast-background);
    --ifm-alert-background-color-highlight: rgba(235, 237, 240, .15);
    --ifm-alert-foreground-color: var(--ifm-color-secondary-contrast-foreground);
    --ifm-alert-border-color: var(--ifm-color-secondary-dark)
}

.alert--success {
    --ifm-alert-background-color: var(--ifm-color-success-contrast-background);
    --ifm-alert-background-color-highlight: rgba(0, 164, 0, .15);
    --ifm-alert-foreground-color: var(--ifm-color-success-contrast-foreground);
    --ifm-alert-border-color: var(--ifm-color-success-dark)
}

.alert--info {
    --ifm-alert-background-color: var(--ifm-color-info-contrast-background);
    --ifm-alert-background-color-highlight: rgba(84, 199, 236, .15);
    --ifm-alert-foreground-color: var(--ifm-color-info-contrast-foreground);
    --ifm-alert-border-color: var(--ifm-color-info-dark)
}

.alert--warning {
    --ifm-alert-background-color: var(--ifm-color-warning-contrast-background);
    --ifm-alert-background-color-highlight: rgba(255, 186, 0, .15);
    --ifm-alert-foreground-color: var(--ifm-color-warning-contrast-foreground);
    --ifm-alert-border-color: var(--ifm-color-warning-dark)
}

.alert--danger {
    --ifm-alert-background-color: var(--ifm-color-danger-contrast-background);
    --ifm-alert-background-color-highlight: rgba(250, 56, 62, .15);
    --ifm-alert-foreground-color: var(--ifm-color-danger-contrast-foreground);
    --ifm-alert-border-color: var(--ifm-color-danger-dark)
}

.alert {
    --ifm-code-background: var(--ifm-alert-background-color-highlight);
    --ifm-link-color: var(--ifm-alert-foreground-color);
    --ifm-link-hover-color: var(--ifm-alert-foreground-color);
    --ifm-link-decoration: underline;
    --ifm-tabs-color: var(--ifm-alert-foreground-color);
    --ifm-tabs-color-active: var(--ifm-alert-foreground-color);
    --ifm-tabs-color-active-border: var(--ifm-alert-border-color);
    background-color: var(--ifm-alert-background-color);
    border: var(--ifm-alert-border-width) solid var(--ifm-alert-border-color);
    border-left-width: var(--ifm-alert-border-left-width);
    border-radius: var(--ifm-alert-border-radius);
    box-shadow: var(--ifm-alert-shadow);
    padding: var(--ifm-alert-padding-vertical) var(--ifm-alert-padding-horizontal)
}

.alert__heading {
    align-items: center;
    display: flex;
    font: 700 var(--ifm-h5-font-size)/var(--ifm-heading-line-height) var(--ifm-heading-font-family);
    margin-bottom: .5rem
}

.alert__icon {
    display: inline-flex;
    margin-right: .4em
}

.alert__icon svg {
    fill: var(--ifm-alert-foreground-color);
    stroke: var(--ifm-alert-foreground-color);
    stroke-width: 0
}

.alert .close {
    margin: calc(var(--ifm-alert-padding-vertical)*-1) calc(var(--ifm-alert-padding-horizontal)*-1) 0 0;
    opacity: .75
}

.alert .close:focus,
.alert .close:hover {
    opacity: 1
}

.alert a {
    -webkit-text-decoration-color: var(--ifm-alert-border-color);
    text-decoration-color: var(--ifm-alert-border-color)
}

.alert a:hover {
    text-decoration-thickness: 2px
}

.avatar {
    column-gap: var(--ifm-avatar-intro-margin);
    display: flex
}

.avatar__photo {
    border-radius: 50%;
    display: block;
    height: var(--ifm-avatar-photo-size);
    overflow: hidden;
    width: var(--ifm-avatar-photo-size)
}

.card--full-height,
.navbar__logo img,
body,
html {
    height: 100%
}

.avatar__photo--sm {
    --ifm-avatar-photo-size: 2rem
}

.avatar__photo--lg {
    --ifm-avatar-photo-size: 4rem
}

.avatar__photo--xl {
    --ifm-avatar-photo-size: 6rem
}

.avatar__intro {
    display: flex;
    flex: 1 1;
    flex-direction: column;
    justify-content: center;
    text-align: var(--ifm-avatar-intro-alignment)
}

.badge,
.breadcrumbs__item,
.breadcrumbs__link,
.button,
.dropdown>.navbar__link:after {
    display: inline-block
}

.avatar__name {
    font: 700 var(--ifm-h4-font-size)/var(--ifm-heading-line-height) var(--ifm-font-family-base)
}

.avatar__subtitle {
    margin-top: .25rem
}

.avatar--vertical {
    --ifm-avatar-intro-alignment: center;
    --ifm-avatar-intro-margin: 0.5rem;
    align-items: center;
    flex-direction: column
}

.badge {
    background-color: var(--ifm-badge-background-color);
    border: var(--ifm-badge-border-width) solid var(--ifm-badge-border-color);
    border-radius: var(--ifm-badge-border-radius);
    color: var(--ifm-badge-color);
    font-size: 75%;
    font-weight: var(--ifm-font-weight-bold);
    line-height: 1;
    padding: var(--ifm-badge-padding-vertical) var(--ifm-badge-padding-horizontal)
}

.badge--primary {
    --ifm-badge-background-color: var(--ifm-color-primary)
}

.badge--secondary {
    --ifm-badge-background-color: var(--ifm-color-secondary);
    color: var(--ifm-color-black)
}

.breadcrumbs__link,
.button.button--secondary.button--outline:not(.button--active):not(:hover) {
    color: var(--ifm-font-color-base)
}

.badge--success {
    --ifm-badge-background-color: var(--ifm-color-success)
}

.badge--info {
    --ifm-badge-background-color: var(--ifm-color-info)
}

.badge--warning {
    --ifm-badge-background-color: var(--ifm-color-warning)
}

.badge--danger {
    --ifm-badge-background-color: var(--ifm-color-danger)
}

.breadcrumbs {
    margin-bottom: 0;
    padding-left: 0
}

.breadcrumbs__item:not(:last-child):after {
    background: var(--ifm-breadcrumb-separator) center;
    content: " ";
    display: inline-block;
    filter: var(--ifm-breadcrumb-separator-filter);
    height: calc(var(--ifm-breadcrumb-separator-size)*var(--ifm-breadcrumb-size-multiplier)*var(--ifm-breadcrumb-separator-size-multiplier));
    margin: 0 var(--ifm-breadcrumb-spacing);
    opacity: .5;
    width: calc(var(--ifm-breadcrumb-separator-size)*var(--ifm-breadcrumb-size-multiplier)*var(--ifm-breadcrumb-separator-size-multiplier))
}

.breadcrumbs__item--active .breadcrumbs__link {
    background: var(--ifm-breadcrumb-item-background-active);
    color: var(--ifm-breadcrumb-color-active)
}

.breadcrumbs__link {
    border-radius: var(--ifm-breadcrumb-border-radius);
    font-size: calc(1rem*var(--ifm-breadcrumb-size-multiplier));
    padding: calc(var(--ifm-breadcrumb-padding-vertical)*var(--ifm-breadcrumb-size-multiplier)) calc(var(--ifm-breadcrumb-padding-horizontal)*var(--ifm-breadcrumb-size-multiplier));
    transition-duration: var(--ifm-transition-fast);
    transition-property: background, color
}

.breadcrumbs__link:link:hover,
.breadcrumbs__link:visited:hover,
area.breadcrumbs__link[href]:hover {
    background: var(--ifm-breadcrumb-item-background-active);
    text-decoration: none
}

.breadcrumbs__link:-webkit-any-link:hover {
    background: var(--ifm-breadcrumb-item-background-active);
    text-decoration: none
}

.breadcrumbs__link:any-link:hover {
    background: var(--ifm-breadcrumb-item-background-active);
    text-decoration: none
}

.breadcrumbs--sm {
    --ifm-breadcrumb-size-multiplier: 0.8
}

.breadcrumbs--lg {
    --ifm-breadcrumb-size-multiplier: 1.2
}

.button {
    background-color: #25c2a0 !important;
    border: var(--ifm-button-border-width) solid var(--ifm-button-border-color);
    border-radius: var(--ifm-button-border-radius);
    cursor: pointer;
    font-size: calc(.875rem*var(--ifm-button-size-multiplier));
    font-weight: var(--ifm-button-font-weight);
    line-height: 1.5;
    padding: calc(var(--ifm-button-padding-vertical)*var(--ifm-button-size-multiplier)) calc(var(--ifm-button-padding-horizontal)*var(--ifm-button-size-multiplier));
    text-align: center;
    transition-duration: var(--ifm-button-transition-duration);
    transition-property: color, background, border-color;
    -webkit-user-select: none;
    user-select: none;
    white-space: nowrap
}

.button,
.button:hover {
    color: var(--ifm-button-color)
}

.button--outline {
    --ifm-button-color: var(--ifm-button-border-color)
}

.button--outline:hover {
    --ifm-button-background-color: var(--ifm-button-border-color)
}

.button--link {
    --ifm-button-border-color: transparent;
    color: var(--ifm-link-color);
    text-decoration: var(--ifm-link-decoration)
}

.button--link.button--active,
.button--link:active,
.button--link:hover {
    color: var(--ifm-link-hover-color);
    text-decoration: var(--ifm-link-hover-decoration)
}

.button.disabled,
.button:disabled,
.button[disabled] {
    opacity: .65;
    pointer-events: none
}

.button--sm {
    --ifm-button-size-multiplier: 0.8
}

.button--lg {
    --ifm-button-size-multiplier: 1.35
}

.button--block {
    display: block;
    width: 100%
}

.button.button--secondary {
    color: var(--ifm-color-gray-900)
}

:where(.button--primary) {
    --ifm-button-background-color: var(--ifm-color-primary);
    --ifm-button-border-color: var(--ifm-color-primary)
}

:where(.button--primary):not(.button--outline):hover {
    --ifm-button-background-color: var(--ifm-color-primary-dark);
    --ifm-button-border-color: var(--ifm-color-primary-dark)
}

.button--primary.button--active,
.button--primary:active {
    --ifm-button-background-color: var(--ifm-color-primary-darker);
    --ifm-button-border-color: var(--ifm-color-primary-darker)
}

:where(.button--secondary) {
    --ifm-button-background-color: var(--ifm-color-secondary);
    --ifm-button-border-color: var(--ifm-color-secondary)
}

:where(.button--secondary):not(.button--outline):hover {
    --ifm-button-background-color: var(--ifm-color-secondary-dark);
    --ifm-button-border-color: var(--ifm-color-secondary-dark)
}

.button--secondary.button--active,
.button--secondary:active {
    --ifm-button-background-color: var(--ifm-color-secondary-darker);
    --ifm-button-border-color: var(--ifm-color-secondary-darker)
}

:where(.button--success) {
    --ifm-button-background-color: var(--ifm-color-success);
    --ifm-button-border-color: var(--ifm-color-success)
}

:where(.button--success):not(.button--outline):hover {
    --ifm-button-background-color: var(--ifm-color-success-dark);
    --ifm-button-border-color: var(--ifm-color-success-dark)
}

.button--success.button--active,
.button--success:active {
    --ifm-button-background-color: var(--ifm-color-success-darker);
    --ifm-button-border-color: var(--ifm-color-success-darker)
}

:where(.button--info) {
    --ifm-button-background-color: var(--ifm-color-info);
    --ifm-button-border-color: var(--ifm-color-info)
}

:where(.button--info):not(.button--outline):hover {
    --ifm-button-background-color: var(--ifm-color-info-dark);
    --ifm-button-border-color: var(--ifm-color-info-dark)
}

.button--info.button--active,
.button--info:active {
    --ifm-button-background-color: var(--ifm-color-info-darker);
    --ifm-button-border-color: var(--ifm-color-info-darker)
}

:where(.button--warning) {
    --ifm-button-background-color: var(--ifm-color-warning);
    --ifm-button-border-color: var(--ifm-color-warning)
}

:where(.button--warning):not(.button--outline):hover {
    --ifm-button-background-color: var(--ifm-color-warning-dark);
    --ifm-button-border-color: var(--ifm-color-warning-dark)
}

.button--warning.button--active,
.button--warning:active {
    --ifm-button-background-color: var(--ifm-color-warning-darker);
    --ifm-button-border-color: var(--ifm-color-warning-darker)
}

:where(.button--danger) {
    --ifm-button-background-color: var(--ifm-color-danger);
    --ifm-button-border-color: var(--ifm-color-danger)
}

:where(.button--danger):not(.button--outline):hover {
    --ifm-button-background-color: var(--ifm-color-danger-dark);
    --ifm-button-border-color: var(--ifm-color-danger-dark)
}

.button--danger.button--active,
.button--danger:active {
    --ifm-button-background-color: var(--ifm-color-danger-darker);
    --ifm-button-border-color: var(--ifm-color-danger-darker)
}

.button-group {
    display: inline-flex;
    gap: var(--ifm-button-group-spacing)
}

.button-group>.button:not(:first-child) {
    border-bottom-left-radius: 0;
    border-top-left-radius: 0
}

.button-group>.button:not(:last-child) {
    border-bottom-right-radius: 0;
    border-top-right-radius: 0
}

.button-group--block {
    display: flex;
    justify-content: stretch
}

.button-group--block>.button {
    flex-grow: 1
}

.card {
    background-color: var(--ifm-card-background-color);
    border-radius: var(--ifm-card-border-radius);
    box-shadow: var(--ifm-global-shadow-lw);
    display: flex;
    flex-direction: column;
    overflow: hidden
}

.card__image {
    padding-top: var(--ifm-card-vertical-spacing)
}

.card__image:first-child {
    padding-top: 0
}

.card__body,
.card__footer,
.card__header {
    padding: var(--ifm-card-vertical-spacing) var(--ifm-card-horizontal-spacing)
}

.card__body:not(:last-child),
.card__footer:not(:last-child),
.card__header:not(:last-child) {
    padding-bottom: 0
}

.card__body>:last-child,
.card__footer>:last-child,
.card__header>:last-child {
    margin-bottom: 0
}

.card__footer {
    margin-top: auto
}

.table-of-contents {
    font-size: .8rem;
    margin-bottom: 0;
    padding: var(--ifm-toc-padding-vertical) 0
}

.table-of-contents,
.table-of-contents ul {
    list-style: none;
    padding-left: var(--ifm-toc-padding-horizontal)
}

.table-of-contents li {
    margin: var(--ifm-toc-padding-vertical) var(--ifm-toc-padding-horizontal)
}

.table-of-contents__left-border {
    border-left: 1px solid var(--ifm-toc-border-color)
}

.table-of-contents__link {
    color: var(--ifm-toc-link-color);
    display: block
}

.table-of-contents__link--active,
.table-of-contents__link--active code,
.table-of-contents__link:hover,
.table-of-contents__link:hover code {
    color: var(--ifm-color-primary);
    text-decoration: none
}

.close {
    color: var(--ifm-color-black);
    float: right;
    font-size: 1.5rem;
    font-weight: var(--ifm-font-weight-bold);
    line-height: 1;
    opacity: .5;
    padding: 1rem;
    transition: opacity var(--ifm-transition-fast) var(--ifm-transition-timing-default)
}

.close:hover {
    opacity: .7
}

.close:focus,
.theme-code-block-highlighted-line .codeLineNumber_Tfdd:before {
    opacity: .8
}

.dropdown {
    display: inline-flex;
    font-weight: var(--ifm-dropdown-font-weight);
    position: relative;
    vertical-align: top
}

.dropdown--hoverable:hover .dropdown__menu,
.dropdown--show .dropdown__menu {
    opacity: 1;
    pointer-events: all;
    transform: translateY(-1px);
    visibility: visible
}

#nprogress,
.dropdown__menu,
.navbar__item.dropdown .navbar__link:not([href]) {
    pointer-events: none
}

.dropdown--right .dropdown__menu {
    left: inherit;
    right: 0
}

.dropdown--nocaret .navbar__link:after {
    content: none !important
}

.dropdown__menu {
    background-color: var(--ifm-dropdown-background-color);
    border-radius: var(--ifm-global-radius);
    box-shadow: var(--ifm-global-shadow-md);
    left: 0;
    max-height: 80vh;
    min-width: 10rem;
    opacity: 0;
    overflow-y: auto;
    padding: .5rem;
    position: absolute;
    top: calc(100% - var(--ifm-navbar-item-padding-vertical) + .3rem);
    transform: translateY(-.625rem);
    transition-duration: var(--ifm-transition-fast);
    transition-property: opacity, transform, visibility;
    transition-timing-function: var(--ifm-transition-timing-default);
    visibility: hidden;
    z-index: var(--ifm-z-index-dropdown)
}

.sidebar_re4s,
.tableOfContents_bqdL {
    max-height: calc(100vh - var(--ifm-navbar-height) - 2rem)
}

.menu__caret,
.menu__link,
.menu__list-item-collapsible {
    border-radius: .25rem;
    transition: background var(--ifm-transition-fast) var(--ifm-transition-timing-default)
}

.dropdown__link {
    border-radius: .25rem;
    color: var(--ifm-dropdown-link-color);
    display: block;
    font-size: .875rem;
    margin-top: .2rem;
    padding: .25rem .5rem;
    white-space: nowrap
}

.dropdown__link--active,
.dropdown__link:hover {
    background-color: var(--ifm-dropdown-hover-background-color);
    color: var(--ifm-dropdown-link-color);
    text-decoration: none
}

.dropdown__link--active,
.dropdown__link--active:hover {
    --ifm-dropdown-link-color: var(--ifm-link-color)
}

.dropdown>.navbar__link:after {
    border-color: currentcolor transparent;
    border-style: solid;
    border-width: .4em .4em 0;
    content: "";
    margin-left: .3em;
    position: relative;
    top: 2px;
    transform: translateY(-50%)
}

.footer {
    background-color: var(--ifm-footer-background-color);
    color: var(--ifm-footer-color);
    padding: var(--ifm-footer-padding-vertical) var(--ifm-footer-padding-horizontal)
}

.footer--dark {
    --ifm-footer-background-color: #303846;
    --ifm-footer-color: var(--ifm-footer-link-color);
    --ifm-footer-link-color: var(--ifm-color-secondary);
    --ifm-footer-title-color: var(--ifm-color-white)
}

.footer__links {
    margin-bottom: 1rem
}

.footer__link-item {
    color: var(--ifm-footer-link-color);
    line-height: 2
}

.footer__link-item:hover {
    color: var(--ifm-footer-link-hover-color)
}

.footer__link-separator {
    margin: 0 var(--ifm-footer-link-horizontal-spacing)
}

.footer__logo {
    margin-top: 1rem;
    max-width: var(--ifm-footer-logo-max-width)
}

.footer__title {
    color: var(--ifm-footer-title-color);
    font: 700 var(--ifm-h4-font-size)/var(--ifm-heading-line-height) var(--ifm-font-family-base);
    margin-bottom: var(--ifm-heading-margin-bottom)
}

.menu,
.navbar__link {
    font-weight: var(--ifm-font-weight-semibold)
}

.docItemContainer_Djhp article>:first-child,
.docItemContainer_Djhp header+*,
.footer__item {
    margin-top: 0
}

.admonitionContent_S0QG>:last-child,
.collapsibleContent_i85q>:last-child,
.footer__items,
.tabItem_Ymn6>:last-child {
    margin-bottom: 0
}

.codeBlockStandalone_MEMb,
[type=checkbox] {
    padding: 0
}

.hero {
    align-items: center;
    background-color: var(--ifm-hero-background-color);
    color: var(--ifm-hero-text-color);
    display: flex;
    padding: 4rem 2rem
}

.hero--primary {
    --ifm-hero-background-color: var(--ifm-color-primary);
    --ifm-hero-text-color: var(--ifm-font-color-base-inverse)
}

.hero--dark {
    --ifm-hero-background-color: #303846;
    --ifm-hero-text-color: var(--ifm-color-white)
}

.hero__title,
.title_f1Hy {
    font-size: 3rem
}

.hero__subtitle {
    font-size: 1.5rem
}

.menu__list {
    margin: 0;
    padding-left: 0
}

.menu__caret,
.menu__link {
    padding: var(--ifm-menu-link-padding-vertical) var(--ifm-menu-link-padding-horizontal)
}

.menu__list .menu__list {
    flex: 0 0 100%;
    margin-top: .25rem;
    padding-left: var(--ifm-menu-link-padding-horizontal)
}

.menu__list-item:not(:first-child) {
    margin-top: .25rem
}

.menu__list-item--collapsed .menu__list {
    height: 0;
    overflow: hidden
}

.details_lb9f[data-collapsed=false].isBrowser_bmU9>summary:before,
.details_lb9f[open]:not(.isBrowser_bmU9)>summary:before,
.menu__list-item--collapsed .menu__caret:before,
.menu__list-item--collapsed .menu__link--sublist:after {
    transform: rotate(90deg)
}

.menu__list-item-collapsible {
    display: flex;
    flex-wrap: wrap;
    position: relative
}

.menu__caret:hover,
.menu__link:hover,
.menu__list-item-collapsible--active,
.menu__list-item-collapsible:hover {
    background: var(--ifm-menu-color-background-hover)
}

.menu__list-item-collapsible .menu__link--active,
.menu__list-item-collapsible .menu__link:hover {
    background: none !important
}

.menu__caret,
.menu__link {
    align-items: center;
    display: flex
}

.menu__link {
    color: var(--ifm-menu-color);
    flex: 1;
    line-height: 1.25
}

.menu__link:hover {
    color: var(--ifm-menu-color);
    text-decoration: none
}

.menu__caret:before,
.menu__link--sublist-caret:after {
    content: "";
    height: 1.25rem;
    transform: rotate(180deg);
    transition: transform var(--ifm-transition-fast) linear;
    width: 1.25rem;
    filter: var(--ifm-menu-link-sublist-icon-filter)
}

.menu__link--sublist-caret:after {
    background: var(--ifm-menu-link-sublist-icon) 50%/2rem 2rem;
    margin-left: auto;
    min-width: 1.25rem
}

.menu__link--active,
.menu__link--active:hover {
    color: var(--ifm-menu-color-active)
}

.navbar__brand,
.navbar__link {
    color: var(--ifm-navbar-link-color)
}

.menu__link--active:not(.menu__link--sublist) {
    background-color: var(--ifm-menu-color-background-active)
}

.menu__caret:before {
    background: var(--ifm-menu-link-sublist-icon) 50%/2rem 2rem
}

.navbar--dark,
html[data-theme=dark] {
    --ifm-menu-link-sublist-icon-filter: invert(100%) sepia(94%) saturate(17%) hue-rotate(223deg) brightness(104%) contrast(98%)
}

.navbar {
    background-color: var(--ifm-navbar-background-color);
    box-shadow: var(--ifm-navbar-shadow);
    height: var(--ifm-navbar-height);
    padding: var(--ifm-navbar-padding-vertical) var(--ifm-navbar-padding-horizontal)
}

.docsWrapper_BCFX,
.navbar,
.navbar>.container,
.navbar>.container-fluid {
    display: flex
}

.navbar--fixed-top {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: var(--ifm-z-index-fixed)
}

.navbar-sidebar,
.navbar-sidebar__backdrop {
    bottom: 0;
    opacity: 0;
    position: fixed;
    transition-timing-function: ease-in-out;
    left: 0;
    top: 0;
    visibility: hidden
}

.navbar__inner {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    width: 100%
}

.navbar__brand {
    align-items: center;
    display: flex;
    margin-right: 1rem;
    min-width: 0
}

.navbar__brand:hover {
    color: var(--ifm-navbar-link-hover-color);
    text-decoration: none
}

.navbar__title {
    flex: 1 1 auto
}

.navbar__toggle {
    display: none;
    margin-right: .5rem
}

.navbar__logo {
    flex: 0 0 auto;
    height: 2rem;
    margin-right: .5rem
}

.navbar__items {
    align-items: center;
    display: flex;
    flex: 1;
    min-width: 0
}

.navbar__items--center {
    flex: 0 0 auto
}

.navbar__items--center .navbar__brand {
    margin: 0
}

.navbar__items--center+.navbar__items--right {
    flex: 1
}

.navbar__items--right {
    flex: 0 0 auto;
    justify-content: flex-end
}

.navbar__items--right>:last-child {
    padding-right: 0
}

.navbar__item {
    display: inline-block;
    padding: var(--ifm-navbar-item-padding-vertical) var(--ifm-navbar-item-padding-horizontal)
}

.navbar__link--active,
.navbar__link:hover {
    color: var(--ifm-navbar-link-hover-color);
    text-decoration: none
}

.navbar--dark,
.navbar--primary {
    --ifm-menu-color: var(--ifm-color-gray-300);
    --ifm-navbar-link-color: var(--ifm-color-gray-100);
    --ifm-navbar-search-input-background-color: hsla(0, 0%, 100%, .1);
    --ifm-navbar-search-input-placeholder-color: hsla(0, 0%, 100%, .5);
    color: var(--ifm-color-white)
}

.navbar--dark {
    --ifm-navbar-background-color: #242526;
    --ifm-menu-color-background-active: hsla(0, 0%, 100%, .05);
    --ifm-navbar-search-input-color: var(--ifm-color-white)
}

.navbar--primary {
    --ifm-navbar-background-color: var(--ifm-color-primary);
    --ifm-navbar-link-hover-color: var(--ifm-color-white);
    --ifm-menu-color-active: var(--ifm-color-white);
    --ifm-navbar-search-input-color: var(--ifm-color-emphasis-500)
}

.navbar__search-input {
    -webkit-appearance: none;
    appearance: none;
    background: var(--ifm-navbar-search-input-background-color) var(--ifm-navbar-search-input-icon) no-repeat .75rem center/1rem 1rem;
    border: none;
    border-radius: 2rem;
    color: var(--ifm-navbar-search-input-color);
    cursor: text;
    display: inline-block;
    font-size: .9rem;
    height: 2rem;
    padding: 0 .5rem 0 2.25rem;
    width: 12.5rem
}

.navbar__search-input::placeholder {
    color: var(--ifm-navbar-search-input-placeholder-color)
}

.navbar-sidebar {
    background-color: var(--ifm-navbar-background-color);
    box-shadow: var(--ifm-global-shadow-md);
    transform: translate3d(-100%, 0, 0);
    transition-duration: .25s;
    transition-property: opacity, visibility, transform;
    width: var(--ifm-navbar-sidebar-width)
}

.navbar-sidebar--show .navbar-sidebar,
.navbar-sidebar__items {
    transform: translateZ(0)
}

.navbar-sidebar--show .navbar-sidebar,
.navbar-sidebar--show .navbar-sidebar__backdrop {
    opacity: 1;
    visibility: visible
}

.navbar-sidebar__backdrop {
    background-color: rgba(0, 0, 0, .6);
    right: 0;
    transition-duration: .1s;
    transition-property: opacity, visibility
}

.navbar-sidebar__brand {
    align-items: center;
    box-shadow: var(--ifm-navbar-shadow);
    display: flex;
    flex: 1;
    height: var(--ifm-navbar-height);
    padding: var(--ifm-navbar-padding-vertical) var(--ifm-navbar-padding-horizontal)
}

.navbar-sidebar__items {
    display: flex;
    height: calc(100% - var(--ifm-navbar-height));
    transition: transform var(--ifm-transition-fast) ease-in-out
}

.navbar-sidebar__items--show-secondary {
    transform: translate3d(calc((var(--ifm-navbar-sidebar-width))*-1), 0, 0)
}

.navbar-sidebar__item {
    flex-shrink: 0;
    padding: .5rem;
    width: calc(var(--ifm-navbar-sidebar-width))
}

.navbar-sidebar__back {
    background: var(--ifm-menu-color-background-active);
    font-size: 15px;
    font-weight: var(--ifm-button-font-weight);
    margin: 0 0 .2rem -.5rem;
    padding: .6rem 1.5rem;
    position: relative;
    text-align: left;
    top: -.5rem;
    width: calc(100% + 1rem)
}

.navbar-sidebar__close {
    display: flex;
    margin-left: auto
}

.pagination {
    column-gap: var(--ifm-pagination-page-spacing);
    display: flex;
    font-size: var(--ifm-pagination-font-size);
    padding-left: 0
}

.pagination--sm {
    --ifm-pagination-font-size: 0.8rem;
    --ifm-pagination-padding-horizontal: 0.8rem;
    --ifm-pagination-padding-vertical: 0.2rem
}

.pagination--lg {
    --ifm-pagination-font-size: 1.2rem;
    --ifm-pagination-padding-horizontal: 1.2rem;
    --ifm-pagination-padding-vertical: 0.3rem
}

.pagination__item {
    display: inline-flex
}

.pagination__item>span {
    padding: var(--ifm-pagination-padding-vertical)
}

.pagination__item--active .pagination__link {
    color: var(--ifm-pagination-color-active)
}

.pagination__item--active .pagination__link,
.pagination__item:not(.pagination__item--active):hover .pagination__link {
    background: var(--ifm-pagination-item-active-background)
}

.pagination__item--disabled,
.pagination__item[disabled] {
    opacity: .25;
    pointer-events: none
}

.pagination__link {
    border-radius: var(--ifm-pagination-border-radius);
    color: var(--ifm-font-color-base);
    display: inline-block;
    padding: var(--ifm-pagination-padding-vertical) var(--ifm-pagination-padding-horizontal);
    transition: background var(--ifm-transition-fast) var(--ifm-transition-timing-default)
}

.pagination__link:hover,
.sidebarItemLink_mo7H:hover {
    text-decoration: none
}

.pagination-nav {
    grid-gap: var(--ifm-spacing-horizontal);
    display: grid;
    gap: var(--ifm-spacing-horizontal);
    grid-template-columns: repeat(2, 1fr)
}

.pagination-nav__link {
    border: 1px solid var(--ifm-color-emphasis-300);
    border-radius: var(--ifm-pagination-nav-border-radius);
    display: block;
    height: 100%;
    line-height: var(--ifm-heading-line-height);
    padding: var(--ifm-global-spacing);
    transition: border-color var(--ifm-transition-fast) var(--ifm-transition-timing-default)
}

.pagination-nav__link:hover {
    border-color: var(--ifm-pagination-nav-color-hover);
    text-decoration: none
}

.pagination-nav__link--next {
    grid-column: 2/3;
    text-align: right
}

.pagination-nav__label {
    font-size: var(--ifm-h4-font-size);
    font-weight: var(--ifm-heading-font-weight);
    word-break: break-word
}

.pagination-nav__link--prev .pagination-nav__label:before {
    content: "« "
}

.pagination-nav__link--next .pagination-nav__label:after {
    content: " »"
}

.pagination-nav__sublabel {
    color: var(--ifm-color-content-secondary);
    font-size: var(--ifm-h5-font-size);
    font-weight: var(--ifm-font-weight-semibold);
    margin-bottom: .25rem
}

.pills__item,
.sidebarItemTitle_pO2u,
.tabs {
    font-weight: var(--ifm-font-weight-bold)
}

.pills {
    display: flex;
    gap: var(--ifm-pills-spacing);
    padding-left: 0
}

.pills__item {
    border-radius: .5rem;
    cursor: pointer;
    display: inline-block;
    padding: .25rem 1rem;
    transition: background var(--ifm-transition-fast) var(--ifm-transition-timing-default)
}

.tabs,
:not(.containsTaskList_mC6p>li)>.containsTaskList_mC6p {
    padding-left: 0
}

.pills__item--active {
    color: var(--ifm-pills-color-active)
}

.pills__item--active,
.pills__item:not(.pills__item--active):hover {
    background: var(--ifm-pills-color-background-active)
}

.pills--block {
    justify-content: stretch
}

.pills--block .pills__item {
    flex-grow: 1;
    text-align: center
}

.tabs {
    color: var(--ifm-tabs-color);
    display: flex;
    margin-bottom: 0;
    overflow-x: auto
}

.tabs__item {
    border-bottom: 3px solid transparent;
    border-radius: var(--ifm-global-radius);
    cursor: pointer;
    display: inline-flex;
    padding: var(--ifm-tabs-padding-vertical) var(--ifm-tabs-padding-horizontal);
    transition: background-color var(--ifm-transition-fast) var(--ifm-transition-timing-default)
}

.tabs__item--active {
    border-bottom-color: var(--ifm-tabs-color-active-border);
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    color: var(--ifm-tabs-color-active)
}

.tabs__item:hover {
    background-color: var(--ifm-hover-overlay)
}

.tabs--block {
    justify-content: stretch
}

.tabs--block .tabs__item {
    flex-grow: 1;
    justify-content: center
}

html[data-theme=dark] {
    --ifm-color-scheme: dark;
    --ifm-color-emphasis-0: var(--ifm-color-gray-1000);
    --ifm-color-emphasis-100: var(--ifm-color-gray-900);
    --ifm-color-emphasis-200: var(--ifm-color-gray-800);
    --ifm-color-emphasis-300: var(--ifm-color-gray-700);
    --ifm-color-emphasis-400: var(--ifm-color-gray-600);
    --ifm-color-emphasis-600: var(--ifm-color-gray-400);
    --ifm-color-emphasis-700: var(--ifm-color-gray-300);
    --ifm-color-emphasis-800: var(--ifm-color-gray-200);
    --ifm-color-emphasis-900: var(--ifm-color-gray-100);
    --ifm-color-emphasis-1000: var(--ifm-color-gray-0);
    --ifm-background-color: #1b1b1d;
    --ifm-background-surface-color: #242526;
    --ifm-hover-overlay: hsla(0, 0%, 100%, .05);
    --ifm-color-content: #e3e3e3;
    --ifm-color-content-secondary: #fff;
    --ifm-breadcrumb-separator-filter: invert(64%) sepia(11%) saturate(0%) hue-rotate(149deg) brightness(99%) contrast(95%);
    --ifm-code-background: hsla(0, 0%, 100%, .1);
    --ifm-scrollbar-track-background-color: #444;
    --ifm-scrollbar-thumb-background-color: #686868;
    --ifm-scrollbar-thumb-hover-background-color: #7a7a7a;
    --ifm-table-stripe-background: hsla(0, 0%, 100%, .07);
    --ifm-toc-border-color: var(--ifm-color-emphasis-200);
    --ifm-color-primary-contrast-background: #102445;
    --ifm-color-primary-contrast-foreground: #ebf2fc;
    --ifm-color-secondary-contrast-background: #474748;
    --ifm-color-secondary-contrast-foreground: #fdfdfe;
    --ifm-color-success-contrast-background: #003100;
    --ifm-color-success-contrast-foreground: #e6f6e6;
    --ifm-color-info-contrast-background: #193c47;
    --ifm-color-info-contrast-foreground: #eef9fd;
    --ifm-color-warning-contrast-background: #4d3800;
    --ifm-color-warning-contrast-foreground: #fff8e6;
    --ifm-color-danger-contrast-background: #4b1113;
    --ifm-color-danger-contrast-foreground: #ffebec
}

#nprogress .bar {
    background: var(--docusaurus-progress-bar-color);
    height: 2px;
    left: 0;
    position: fixed;
    top: 0;
    width: 100%;
    z-index: 1031
}

#nprogress .peg,
.visualizeCanvasWrapper_YP4_ .visualizeCanvas_bKhT {
    position: absolute
}

#nprogress .peg {
    box-shadow: 0 0 10px var(--docusaurus-progress-bar-color), 0 0 5px var(--docusaurus-progress-bar-color);
    height: 100%;
    opacity: 1;
    right: 0;
    transform: rotate(3deg) translateY(-4px);
    width: 100px
}

.docusaurus-highlight-code-line {
    background-color: #484d5b;
    display: block;
    margin: 0 calc(var(--ifm-pre-padding)*-1);
    padding: 0 var(--ifm-pre-padding)
}

body:not(.navigation-with-keyboard) :not(input):focus {
    outline: 0
}

#docusaurus-base-url-issue-banner-container,
.collapseSidebarButton_PEFL,
.docSidebarContainer_b6E3,
.sidebarLogo_isFc,
.themedImage_ToTc,
[data-theme=dark] .lightToggleIcon_pyhR,
[data-theme=light] .darkToggleIcon_wfgR,
html[data-announcement-bar-initially-dismissed=true] .announcementBar_mb4j {
    display: none
}

.skipToContent_fXgn {
    background-color: var(--ifm-background-surface-color);
    color: var(--ifm-color-emphasis-900);
    left: 100%;
    padding: calc(var(--ifm-global-spacing)/2) var(--ifm-global-spacing);
    position: fixed;
    top: 1rem;
    z-index: calc(var(--ifm-z-index-fixed) + 1)
}

.skipToContent_fXgn:focus {
    box-shadow: var(--ifm-global-shadow-md);
    left: 1rem
}

.announcementBar_mb4j {
    align-items: center;
    background-color: var(--ifm-color-white);
    border-bottom: 1px solid var(--ifm-color-emphasis-100);
    color: var(--ifm-color-black);
    display: flex;
    height: var(--docusaurus-announcement-bar-height)
}

.announcementBarPlaceholder_vyr4 {
    flex: 0 0 10px
}

.announcementBarClose_gvF7 {
    align-self: stretch;
    flex: 0 0 30px;
    line-height: 0;
    padding: 0
}

.announcementBarContent_xLdY {
    flex: 1 1 auto;
    font-size: 85%;
    padding: 5px 0;
    text-align: center
}

.announcementBarContent_xLdY a {
    color: inherit;
    text-decoration: underline
}

.toggle_vylO {
    height: 2rem;
    width: 2rem
}

.toggleButton_gllP {
    align-items: center;
    border-radius: 50%;
    display: flex;
    height: 100%;
    justify-content: center;
    transition: background var(--ifm-transition-fast);
    width: 100%
}

.toggleButton_gllP:hover {
    background: var(--ifm-color-emphasis-200)
}

.toggleButtonDisabled_aARS {
    cursor: not-allowed
}

[data-theme=dark] .themedImage--dark_i4oU,
[data-theme=light] .themedImage--light_HNdA {
    display: initial
}

.iconExternalLink_nPIU {
    margin-left: .3rem
}

.iconLanguage_nlXk {
    margin-right: 5px;
    vertical-align: text-bottom
}

.navbarHideable_m1mJ {
    transition: transform var(--ifm-transition-fast) ease
}

.navbarHidden_jGov {
    transform: translate3d(0, calc(-100% - 2px), 0)
}

.footerLogoLink_BH7S {
    opacity: .5;
    transition: opacity var(--ifm-transition-fast) var(--ifm-transition-timing-default)
}

.footerLogoLink_BH7S:hover,
.hash-link:focus,
:hover>.hash-link {
    opacity: 1
}

.mainWrapper_z2l0 {
    flex: 1 0 auto
}

.docusaurus-mt-lg {
    margin-top: 3rem
}

#__docusaurus {
    display: flex;
    flex-direction: column;
    min-height: 100%
}

.sidebar_re4s {
    overflow-y: auto;
    position: -webkit-sticky;
    position: sticky;
    top: calc(var(--ifm-navbar-height) + 2rem)
}

.sidebarItemTitle_pO2u {
    font-size: var(--ifm-h3-font-size)
}

.container_mt6G,
.sidebarItemList_Yudw {
    font-size: .9rem
}

.sidebarItem__DBe {
    margin-top: .7rem
}

.sidebarItemLink_mo7H {
    color: var(--ifm-font-color-base);
    display: block
}

.sidebarItemLinkActive_I1ZP {
    color: var(--ifm-color-primary) !important
}

.backToTopButton_sjWU {
    background-color: var(--ifm-color-emphasis-200);
    border-radius: 50%;
    bottom: 1.3rem;
    box-shadow: var(--ifm-global-shadow-lw);
    height: 3rem;
    opacity: 0;
    position: fixed;
    right: 1.3rem;
    transform: scale(0);
    transition: all var(--ifm-transition-fast) var(--ifm-transition-timing-default);
    visibility: hidden;
    width: 3rem;
    z-index: calc(var(--ifm-z-index-fixed) - 1)
}

.buttonGroup__atx button,
.codeBlockContainer_Ckt0 {
    background: var(--prism-background-color);
    color: var(--prism-color)
}

.backToTopButton_sjWU:after {
    background-color: var(--ifm-color-emphasis-1000);
    content: " ";
    display: inline-block;
    height: 100%;
    -webkit-mask: var(--ifm-menu-link-sublist-icon) 50%/2rem 2rem no-repeat;
    mask: var(--ifm-menu-link-sublist-icon) 50%/2rem 2rem no-repeat;
    width: 100%
}

.backToTopButtonShow_xfvO {
    opacity: 1;
    transform: scale(1);
    visibility: visible
}

[data-theme=dark]:root {
    --docusaurus-collapse-button-bg: hsla(0, 0%, 100%, .05);
    --docusaurus-collapse-button-bg-hover: hsla(0, 0%, 100%, .1)
}

.docMainContainer_gTbr,
.docPage__5DB {
    display: flex;
    width: 100%
}

.heroBanner_UJJx {
    overflow: hidden;
    padding: 4rem 0;
    position: relative;
    text-align: center
}

.buttons_pzbO,
.mdxPageWrapper_j9I6 {
    justify-content: center
}

.buttons_pzbO,
.features_keug,
.visualizeCanvasWrapper_YP4_ {
    align-items: center;
    display: flex
}

.features_keug {
    padding: 2rem 0;
    width: 100%
}

.featureImage_yA8i {
    height: 200px;
    width: 200px
}

.authorCol_Hf19 {
    flex-grow: 1 !important;
    max-width: inherit !important
}

.imageOnlyAuthorRow_pa_O {
    display: flex;
    flex-flow: row wrap
}

.imageOnlyAuthorCol_G86a {
    margin-left: .3rem;
    margin-right: .3rem
}

.startButton_OY2G {
    width: 100%
}

.visualizeCanvasWrapper_YP4_ {
    height: 0;
    justify-content: center;
    padding-top: 100%;
    position: relative;
    text-align: center;
    width: 100%
}

.codeBlockContainer_Ckt0 {
    border-radius: var(--ifm-code-border-radius);
    box-shadow: var(--ifm-global-shadow-lw);
    margin-bottom: var(--ifm-leading)
}

.codeBlockContent_biex {
    border-radius: inherit;
    direction: ltr;
    position: relative
}

.codeBlockTitle_Ktv7 {
    border-bottom: 1px solid var(--ifm-color-emphasis-300);
    border-top-left-radius: inherit;
    border-top-right-radius: inherit;
    font-size: var(--ifm-code-font-size);
    font-weight: 500;
    padding: .75rem var(--ifm-pre-padding)
}

.codeBlock_bY9V {
    --ifm-pre-background: var(--prism-background-color);
    margin: 0;
    padding: 0
}

.codeBlockTitle_Ktv7+.codeBlockContent_biex .codeBlock_bY9V {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

.codeBlockLines_e6Vv {
    float: left;
    font: inherit;
    min-width: 100%;
    padding: var(--ifm-pre-padding)
}

.codeBlockLinesWithNumbering_o6Pm {
    display: table;
    padding: var(--ifm-pre-padding) 0
}

.buttonGroup__atx {
    column-gap: .2rem;
    display: flex;
    position: absolute;
    right: calc(var(--ifm-pre-padding)/2);
    top: calc(var(--ifm-pre-padding)/2)
}

.buttonGroup__atx button {
    align-items: center;
    border: 1px solid var(--ifm-color-emphasis-300);
    border-radius: var(--ifm-global-radius);
    display: flex;
    line-height: 0;
    opacity: 0;
    padding: .4rem;
    transition: opacity .2s ease-in-out
}

.buttonGroup__atx button:focus-visible,
.buttonGroup__atx button:hover {
    opacity: 1 !important
}

.theme-code-block:hover .buttonGroup__atx button {
    opacity: .4
}

.iconEdit_Z9Sw {
    margin-right: .3em;
    vertical-align: sub
}

:where(:root) {
    --docusaurus-highlighted-code-line-bg: #484d5b
}

:where([data-theme=dark]) {
    --docusaurus-highlighted-code-line-bg: #646464
}

.theme-code-block-highlighted-line {
    background-color: var(--docusaurus-highlighted-code-line-bg);
    display: block;
    margin: 0 calc(var(--ifm-pre-padding)*-1);
    padding: 0 var(--ifm-pre-padding)
}

.codeLine_lJS_ {
    counter-increment: a;
    display: table-row
}

.codeLineNumber_Tfdd {
    background: var(--ifm-pre-background);
    display: table-cell;
    left: 0;
    padding: 0 var(--ifm-pre-padding);
    position: -webkit-sticky;
    position: sticky;
    text-align: right;
    width: 1%
}

.codeLineNumber_Tfdd:before {
    content: counter(a);
    opacity: .4
}

.codeLineContent_feaV {
    padding-right: var(--ifm-pre-padding)
}

.tag_zVej {
    border: 1px solid var(--docusaurus-tag-list-border);
    transition: border var(--ifm-transition-fast)
}

.tag_zVej:hover {
    --docusaurus-tag-list-border: var(--ifm-link-color);
    text-decoration: none
}

.tagRegular_sFm0 {
    border-radius: .5rem;
    font-size: 90%;
    padding: .3rem .5rem
}

.tagWithCount_h2kH {
    align-items: center;
    border-left: 0;
    display: flex;
    padding: 0 .5rem 0 1rem;
    position: relative
}

.tagWithCount_h2kH:after,
.tagWithCount_h2kH:before {
    border: 1px solid var(--docusaurus-tag-list-border);
    content: "";
    position: absolute;
    top: 50%;
    transition: inherit
}

.tagWithCount_h2kH:before {
    border-bottom: 0;
    border-right: 0;
    height: 1.18rem;
    right: 100%;
    transform: translate(50%, -50%) rotate(-45deg);
    width: 1.18rem
}

.tagWithCount_h2kH:after {
    border-radius: 50%;
    height: .5rem;
    left: 0;
    transform: translateY(-50%);
    width: .5rem
}

.tagWithCount_h2kH span {
    background: var(--ifm-color-secondary);
    border-radius: var(--ifm-global-radius);
    color: var(--ifm-color-black);
    font-size: .7rem;
    line-height: 1.2;
    margin-left: .3rem;
    padding: .1rem .4rem
}

.tag_Nnez {
    display: inline-block;
    margin: .5rem .5rem 0 1rem
}

.theme-code-block:hover .copyButtonCopied_obH4 {
    opacity: 1 !important
}

.copyButtonIcons_eSgA {
    height: 1.125rem;
    position: relative;
    width: 1.125rem
}

.copyButtonIcon_y97N,
.copyButtonSuccessIcon_LjdS {
    fill: currentColor;
    height: inherit;
    left: 0;
    opacity: inherit;
    position: absolute;
    top: 0;
    transition: .15s;
    width: inherit
}

.copyButtonSuccessIcon_LjdS {
    color: #00d600;
    left: 50%;
    opacity: 0;
    top: 50%;
    transform: translate(-50%, -50%) scale(.33)
}

.copyButtonCopied_obH4 .copyButtonIcon_y97N {
    opacity: 0;
    transform: scale(.33)
}

.copyButtonCopied_obH4 .copyButtonSuccessIcon_LjdS {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
    transition-delay: 75ms
}

.tags_jXut {
    display: inline
}

.tag_QGVx {
    display: inline-block;
    margin: 0 .4rem .5rem 0
}

.lastUpdated_vwxv {
    font-size: smaller;
    font-style: italic;
    margin-top: .2rem
}

.tocCollapsibleButton_TO0P {
    align-items: center;
    display: flex;
    font-size: inherit;
    justify-content: space-between;
    padding: .4rem .8rem;
    width: 100%
}

.tocCollapsibleButton_TO0P:after {
    background: var(--ifm-menu-link-sublist-icon) 50% 50%/2rem 2rem no-repeat;
    content: "";
    filter: var(--ifm-menu-link-sublist-icon-filter);
    height: 1.25rem;
    transform: rotate(180deg);
    transition: transform var(--ifm-transition-fast);
    width: 1.25rem
}

.tocCollapsibleButtonExpanded_MG3E:after,
.tocCollapsibleExpanded_sAul {
    transform: none
}

.tocCollapsible_ETCw {
    background-color: var(--ifm-menu-color-background-active);
    border-radius: var(--ifm-global-radius);
    margin: 1rem 0
}

.tocCollapsibleContent_vkbj>ul {
    border-left: none;
    border-top: 1px solid var(--ifm-color-emphasis-300);
    font-size: 15px;
    padding: .2rem 0
}

.tocCollapsibleContent_vkbj ul li {
    margin: .4rem .8rem
}

.tocCollapsibleContent_vkbj a {
    display: block
}

.wordWrapButtonIcon_Bwma {
    height: 1.2rem;
    width: 1.2rem
}

.details_lb9f {
    --docusaurus-details-summary-arrow-size: 0.38rem;
    --docusaurus-details-transition: transform 200ms ease;
    --docusaurus-details-decoration-color: grey
}

.details_lb9f>summary {
    cursor: pointer;
    padding-left: 1rem;
    position: relative
}

.details_lb9f>summary::-webkit-details-marker {
    display: none
}

.details_lb9f>summary:before {
    border-color: transparent transparent transparent var(--docusaurus-details-decoration-color);
    border-style: solid;
    border-width: var(--docusaurus-details-summary-arrow-size);
    content: "";
    left: 0;
    position: absolute;
    top: .45rem;
    transform: rotate(0);
    transform-origin: calc(var(--docusaurus-details-summary-arrow-size)/2) 50%;
    transition: var(--docusaurus-details-transition)
}

.collapsibleContent_i85q {
    border-top: 1px solid var(--docusaurus-details-decoration-color);
    margin-top: 1rem;
    padding-top: 1rem
}

.details_b_Ee {
    --docusaurus-details-decoration-color: var(--ifm-alert-border-color);
    --docusaurus-details-transition: transform var(--ifm-transition-fast) ease;
    border: 1px solid var(--ifm-alert-border-color);
    margin: 0 0 var(--ifm-spacing-vertical)
}

.anchorWithStickyNavbar_LWe7 {
    scroll-margin-top: calc(var(--ifm-navbar-height) + .5rem)
}

.anchorWithHideOnScrollNavbar_WYt5 {
    scroll-margin-top: .5rem
}

.hash-link {
    opacity: 0;
    padding-left: .5rem;
    transition: opacity var(--ifm-transition-fast);
    -webkit-user-select: none;
    user-select: none
}

.hash-link:before {
    content: "#"
}

.img_ev3q {
    height: auto
}

.admonition_LlT9 {
    margin-bottom: 1em
}

.admonitionHeading_tbUL {
    font: var(--ifm-heading-font-weight) var(--ifm-h5-font-size)/var(--ifm-heading-line-height) var(--ifm-heading-font-family);
    margin-bottom: .3rem
}

.admonitionHeading_tbUL code {
    text-transform: none
}

.admonitionIcon_kALy {
    display: inline-block;
    margin-right: .4em;
    vertical-align: middle
}

.admonitionIcon_kALy svg {
    fill: var(--ifm-alert-foreground-color);
    display: inline-block;
    height: 1.6em;
    width: 1.6em
}

.blogPostFooterDetailsFull_mRVl {
    flex-direction: column
}

.tableOfContents_bqdL {
    overflow-y: auto;
    position: -webkit-sticky;
    position: sticky;
    top: calc(var(--ifm-navbar-height) + 1rem)
}

.breadcrumbsContainer_Z_bl {
    --ifm-breadcrumb-size-multiplier: 0.8;
    margin-bottom: .8rem
}

.breadcrumbHomeIcon_OVgt {
    height: 1.1rem;
    position: relative;
    top: 1px;
    vertical-align: top;
    width: 1.1rem
}

@media (min-width:997px) {

    .collapseSidebarButton_PEFL,
    .expandButton_m80_ {
        background-color: var(--docusaurus-collapse-button-bg)
    }

    :root {
        --docusaurus-announcement-bar-height: 30px
    }

    .announcementBarClose_gvF7,
    .announcementBarPlaceholder_vyr4 {
        flex-basis: 50px
    }

    .searchBox_ZlJk {
        padding: var(--ifm-navbar-item-padding-vertical) var(--ifm-navbar-item-padding-horizontal)
    }

    .collapseSidebarButton_PEFL {
        border: 1px solid var(--ifm-toc-border-color);
        border-radius: 0;
        bottom: 0;
        display: block !important;
        height: 40px;
        position: -webkit-sticky;
        position: sticky
    }

    .collapseSidebarButtonIcon_kv0_ {
        margin-top: 4px;
        transform: rotate(180deg)
    }

    .expandButtonIcon_BlDH,
    [dir=rtl] .collapseSidebarButtonIcon_kv0_ {
        transform: rotate(0)
    }

    .collapseSidebarButton_PEFL:focus,
    .collapseSidebarButton_PEFL:hover,
    .expandButton_m80_:focus,
    .expandButton_m80_:hover {
        background-color: var(--docusaurus-collapse-button-bg-hover)
    }

    .menuHtmlItem_M9Kj {
        padding: var(--ifm-menu-link-padding-vertical) var(--ifm-menu-link-padding-horizontal)
    }

    .menu_SIkG {
        flex-grow: 1;
        padding: .5rem
    }

    @supports (scrollbar-gutter:stable) {
        .menu_SIkG {
            padding: .5rem 0 .5rem .5rem;
            scrollbar-gutter: stable
        }
    }

    .menuWithAnnouncementBar_GW3s {
        margin-bottom: var(--docusaurus-announcement-bar-height)
    }

    .sidebar_njMd {
        display: flex;
        flex-direction: column;
        height: 100%;
        max-height: 100vh;
        padding-top: var(--ifm-navbar-height);
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        transition: opacity 50ms;
        width: var(--doc-sidebar-width)
    }

    .sidebarWithHideableNavbar_wUlq {
        padding-top: 0
    }

    .sidebarHidden_VK0M {
        height: 0;
        opacity: 0;
        overflow: hidden;
        visibility: hidden
    }

    .sidebarLogo_isFc {
        align-items: center;
        color: inherit !important;
        display: flex !important;
        margin: 0 var(--ifm-navbar-padding-horizontal);
        max-height: var(--ifm-navbar-height);
        min-height: var(--ifm-navbar-height);
        text-decoration: none !important
    }

    .sidebarLogo_isFc img {
        height: 2rem;
        margin-right: .5rem
    }

    .expandButton_m80_ {
        align-items: center;
        display: flex;
        height: 100%;
        justify-content: center;
        max-height: 100vh;
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        transition: background-color var(--ifm-transition-fast) ease
    }

    [dir=rtl] .expandButtonIcon_BlDH {
        transform: rotate(180deg)
    }

    .docSidebarContainer_b6E3 {
        border-right: 1px solid var(--ifm-toc-border-color);
        -webkit-clip-path: inset(0);
        clip-path: inset(0);
        display: block;
        margin-top: calc(var(--ifm-navbar-height)*-1);
        transition: width var(--ifm-transition-fast) ease;
        width: var(--doc-sidebar-width);
        will-change: width
    }

    .docSidebarContainerHidden_b3ry {
        cursor: pointer;
        width: var(--doc-sidebar-hidden-width)
    }

    .docMainContainer_gTbr {
        flex-grow: 1;
        max-width: calc(100% - var(--doc-sidebar-width))
    }

    .docMainContainerEnhanced_Uz_u {
        max-width: calc(100% - var(--doc-sidebar-hidden-width))
    }

    .docItemWrapperEnhanced_czyv {
        max-width: calc(var(--ifm-container-width) + var(--doc-sidebar-width)) !important
    }

    .lastUpdated_vwxv {
        text-align: right
    }

    .tocMobile_ITEo {
        display: none
    }

    .docItemCol_VOVn {
        max-width: 75% !important
    }
}

@media (min-width:1440px) {
    .container {
        max-width: var(--ifm-container-width-xl)
    }
}

@media (max-width:996px) {
    .col {
        --ifm-col-width: 100%;
        flex-basis: var(--ifm-col-width);
        margin-left: 0
    }

    .footer {
        --ifm-footer-padding-horizontal: 0
    }

    .colorModeToggle_DEke,
    .footer__link-separator,
    .navbar__item,
    .sidebar_re4s,
    .tableOfContents_bqdL {
        display: none
    }

    .footer__col {
        margin-bottom: calc(var(--ifm-spacing-vertical)*3)
    }

    .footer__link-item {
        display: block
    }

    .hero {
        padding-left: 0;
        padding-right: 0
    }

    .navbar>.container,
    .navbar>.container-fluid {
        padding: 0
    }

    .navbar__toggle {
        display: inherit
    }

    .navbar__search-input {
        width: 9rem
    }

    .pills--block,
    .tabs--block {
        flex-direction: column
    }

    .searchBox_ZlJk {
        position: absolute;
        right: var(--ifm-navbar-padding-horizontal)
    }

    .docItemContainer_F8PC {
        padding: 0 .3rem
    }
}

@media screen and (max-width:966px) {
    .heroBanner_UJJx {
        padding: 2rem
    }
}

@media (max-width:576px) {
    .markdown h1:first-child {
        --ifm-h1-font-size: 2rem
    }

    .markdown>h2 {
        --ifm-h2-font-size: 1.5rem
    }

    .markdown>h3 {
        --ifm-h3-font-size: 1.25rem
    }

    .title_f1Hy {
        font-size: 2rem
    }
}

@media (hover:hover) {
    .backToTopButton_sjWU:hover {
        background-color: var(--ifm-color-emphasis-300)
    }
}

@media (pointer:fine) {
    .thin-scrollbar {
        scrollbar-width: thin
    }

    .thin-scrollbar::-webkit-scrollbar {
        height: var(--ifm-scrollbar-size);
        width: var(--ifm-scrollbar-size)
    }

    .thin-scrollbar::-webkit-scrollbar-track {
        background: var(--ifm-scrollbar-track-background-color);
        border-radius: 10px
    }

    .thin-scrollbar::-webkit-scrollbar-thumb {
        background: var(--ifm-scrollbar-thumb-background-color);
        border-radius: 10px
    }

    .thin-scrollbar::-webkit-scrollbar-thumb:hover {
        background: var(--ifm-scrollbar-thumb-hover-background-color)
    }
}

@media print {

    .announcementBar_mb4j,
    .footer,
    .menu,
    .navbar,
    .pagination-nav,
    .table-of-contents,
    .tocMobile_ITEo {
        display: none
    }

    .tabs {
        page-break-inside: avoid
    }

    .codeBlockLines_e6Vv {
        white-space: pre-wrap
    }
}